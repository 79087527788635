document.addEventListener('DOMContentLoaded', () => {

    var changeAvatarEl = document.querySelector(".input-file");
    // 'var' mandatory for safari
    if(changeAvatarEl){
        changeAvatarEl.addEventListener('change', (event) => {
        var fileName = event.target.value.split( '\\' ).pop();
        var labelChangeAvatarEl = document.querySelector(".button-input-file");
        if (labelChangeAvatarEl) {
            labelChangeAvatarEl.innerHTML = fileName;
            // after choose a file, we display the name file
        }
      });
    }
    
});