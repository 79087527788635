document.addEventListener('DOMContentLoaded', () => {

	function changeImage(forward){
		const screenshots = document.querySelectorAll(".screenshot");
		const selectedScreenshot = document.querySelector("#screenshots .selected");
		const selectedImageId = Number(selectedScreenshot.dataset.imageid);
		let newSelectedImageId = selectedImageId;
		const screenShotsCount = 2;
		if (forward) {
			if (selectedImageId < screenShotsCount) {
				newSelectedImageId += 1;
			} else {
				newSelectedImageId = 1;
			}
		} else {
			if (selectedImageId == 1) {
				newSelectedImageId = screenShotsCount;
			} else {
				newSelectedImageId -= 1;
			}			
		}
		let imageid = 0;
		screenshots.forEach(function(screenshot) {
		  imageid = screenshot.dataset.imageid;
		  if (imageid == selectedImageId) {
		    screenshot.classList.remove("selected");
		    screenshot.classList.add("unselected");
		  } else if (imageid == newSelectedImageId) {
		    screenshot.classList.remove("unselected");
		    screenshot.classList.add("selected");
		  }
		});
	}

	function displayNextImage() {
		const forward = true;
		changeImage(forward);
	}

	function displayPreviousImage() {
		const forward = false;
		changeImage(forward);
	}

	const buttonNextEl = document.getElementById("pictureNext");
	if (buttonNextEl){
		buttonNextEl.addEventListener('click', (event) => {
		event.preventDefault();
	displayNextImage();
	});
	}

	const buttonPreviousEl = document.getElementById("picturePrevious");
	if (buttonPreviousEl){
		buttonPreviousEl.addEventListener('click', (event) => {
		event.preventDefault();
	displayPreviousImage();
	});
	}

});