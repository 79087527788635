document.addEventListener('DOMContentLoaded', () => {

	function displayReportPicture(reportId) {
		const pictureReports = document.querySelectorAll(".picture-report");
		pictureReports.forEach(function(pictureReport) {
            imageid = pictureReport.dataset.imageid;
            if (imageid != reportId) {
              pictureReport.classList.remove("selected");
              pictureReport.classList.add("unselected");
            } else {
              pictureReport.classList.remove("unselected");
              pictureReport.classList.add("selected");
            }
          });
      }
    
	const listBoxReportsEl = document.getElementById("setting_report_id");
	if (listBoxReportsEl){
		listBoxReportsEl.addEventListener('change', (event) => {
        event.preventDefault();
	    displayReportPicture(event.target.value);
	});
	}

});