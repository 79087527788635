document.addEventListener('DOMContentLoaded', () => {

function setAllDone(value){
  let doneCheckBox = document.querySelectorAll('.points-done');
  if (doneCheckBox){
    change = false;
    doneCheckBox.forEach((checkbox) => {
      if (checkbox.checked == false){
        checkbox.checked = value;
        change = true;
       }
    });
    if (change) {
      setSubmitPointsButtonWarning();
    }
  }
}

let setAllDoneTrueEl = document.getElementById("set-all-done-true");
if (setAllDoneTrueEl){
  setAllDoneTrueEl.addEventListener('click', (event) => {
    setAllDone(true);
  });
}

function setSubmitPointsButtonWarning(){
  let submitPoinstEl = document.getElementById('submit-points');
  if (submitPoinstEl){
    submitPoinstEl.classList.remove("btn-medium-black-yellow");
    submitPoinstEl.classList.add("btn-medium-red-white");
  }
}

let doneCheckBox = document.querySelectorAll('.points-done');
if (doneCheckBox){
  doneCheckBox.forEach((checkbox) => {
    checkbox.addEventListener('change', (event) => {
    setSubmitPointsButtonWarning();
    });
  });
}

function setRedCellIfNotCorrectPoints(edit){
  maxPointsEl = document.getElementById("evaluation-max-points");
  maxBonusEl = document.getElementById("evaluation-max-bonus");
  if (maxPointsEl && maxBonusEl) {
    tdEl = edit.parentNode;
    let val = edit.value;
    const max = Number(maxPointsEl.innerHTML) + Number(maxBonusEl.innerHTML)
    let tdRed = false;
    if (isNaN(val) || val==''){
      tdRed = true;
    } else {
      val = Number(val);
      if (val<0 || val>max) {
        tdRed = true;
      }
    }
    if (tdRed) {
      tdEl.style.backgroundColor = 'red';
    } else {
      tdEl.style.backgroundColor = document.body.style.backgroundColor;
    }
  }
}

let editPoints = document.querySelectorAll('.points-points');
if (editPoints){
  editPoints.forEach((edit) => {
    edit.addEventListener('change', (event) => {
    setSubmitPointsButtonWarning();
    });
    edit.addEventListener('input', (event) => {
    setRedCellIfNotCorrectPoints(event.target);
    });
    edit.addEventListener('focus', (event) => {
      // to translate in js, use gem "i18n-js" . setup : https://github.com/fnando/i18n-js
      // console.log(I18n.t("shared.home.make_it_easier", {defaultValue: "Make it easier !"}))
      event.target.select();
    });
  });
}

let eyeButtons = document.querySelectorAll('.eye-button');
if (eyeButtons){
  eyeButtons.forEach((eye) => {
    eye.addEventListener('click', (event) => {
      periodEl = event.target.parentNode.parentNode.parentNode.parentNode;
      tablesEl = periodEl.getElementsByTagName("table");
      for (let tableEl of tablesEl) {
        if (tableEl.dataset.table == event.target.parentNode.dataset.eye){
          if (tableEl.style.display == 'none'){
            tableEl.style.display = '';
          }
          else{
            tableEl.style.display = 'none';
          }      
        }
      }
    });
  });
}

function expandRow(trEl, styleRow){     
  if (trEl.nodeName == 'TD') {
    trEl = trEl.parentNode;
  }
  expandValue = trEl.dataset.expand;
  if (expandValue == 'true'){
    trEl.dataset.expand = 'false';
    visible = false;
  }else{
    trEl.dataset.expand = 'true';
    visible = true;
  }
  findBool = true
  while(trEl && findBool){
    trEl = trEl.nextElementSibling;
    if (trEl) {
      canChangeVisible = false;
      switch(styleRow) {
          case 'category':
              canChangeVisible = !trEl.classList.contains('category-row');
              break;
          case 'skill':
              canChangeVisible = !(trEl.classList.contains('category-row') || trEl.classList.contains('skill-row'));
              break;
          default:
              canChangeVisible = false;
      }
      if (canChangeVisible) {
        if (visible){
          trEl.style.display = ''; 
          if (styleRow == 'category' && trEl.classList.contains('skill-row')){
            trEl.dataset.expand = 'true';
          }
        }
        else{
          trEl.style.display = 'none';
        }     
      }else{
        findBool = false;
      }
    }
  }
}

let skillRows = document.querySelectorAll('.skill-row');
if (skillRows){
  skillRows.forEach((row) => {
    row.addEventListener('click', (event) => {
      tdEl = event.target;
      trEl = tdEl.parentNode;
      expandRow(trEl, 'skill');
    });
  });
}
let categoryRows = document.querySelectorAll('.category-row');
if (categoryRows){
  categoryRows.forEach((row) => {
    row.addEventListener('click', (event) => {
      tdEl = event.target;
      trEl = tdEl.parentNode;
      expandRow(trEl, 'category');
    });
    expandRow(row, 'category');
  });
}



});
