document.addEventListener('DOMContentLoaded', () => {

var penEditNoteEl = document.getElementById("pen-edit-note");
var noteEl = document.getElementById("note");
var editNoteEl = document.getElementById("edit-note");
// 'var' mandatory for safari
if(penEditNoteEl && editNoteEl && noteEl){
  penEditNoteEl.addEventListener('click', (event) => {
    // noteEl.style.display = 'none';
    editNoteEl.classList.remove("not-visible");
  });
}

var observationsdropdownEl = document.querySelector(".observations-dropdown");
var observationschangedescriptionsubmitEl = document.querySelector(".observations-change-description-submit");
if(observationsdropdownEl && observationschangedescriptionsubmitEl){
  observationsdropdownEl.selectedIndex = 0;
  observationsdropdownEl.addEventListener('change', (event) => {
    observationschangedescriptionsubmitEl.click();
  });
}

function replacenames(newnote, firstname, lastname) {
  let result = newnote;
  result = result.replaceAll('$1$', firstname);
  result = result.replaceAll('$2$', lastname);
  result = result.replaceAll('<br>', '\n');
  return result;
}

var predefined_observation_descriptionEl = document.getElementById('predefined_observation_description');
var noteobservationEl = document.getElementById("note_observation");
var insertobservationdescriptionbeginEl = document.getElementById("insert-observation-description-begin");
var replaceobservationdescriptionEl = document.getElementById("replace-observation-description");
var insertobservationdescriptionendEl = document.getElementById("insert-observation-description-end");
var abortnotechangeEl = document.getElementById("abort-note-change");
var firstname = "";
var lastname = "";
var newnote = "";
if(predefined_observation_descriptionEl && noteobservationEl && insertobservationdescriptionbeginEl && replaceobservationdescriptionEl && insertobservationdescriptionendEl){
  firstname = insertobservationdescriptionbeginEl.dataset.firstname;
  lastname = insertobservationdescriptionbeginEl.dataset.lastname;
  insertobservationdescriptionbeginEl.addEventListener('click', (event) => {
    if (noteobservationEl.value == ""){
      newnote = predefined_observation_descriptionEl.innerHTML;
    }else{
      newnote = predefined_observation_descriptionEl.innerHTML + '\n' + noteobservationEl.value;
    }
    noteobservationEl.value = replacenames(newnote, firstname, lastname);
  });
  replaceobservationdescriptionEl.addEventListener('click', (event) => {
    newnote = predefined_observation_descriptionEl.innerHTML;
    noteobservationEl.value = replacenames(newnote, firstname, lastname);
  });
  insertobservationdescriptionendEl.addEventListener('click', (event) => {
    if (noteobservationEl.value == ""){
      newnote = predefined_observation_descriptionEl.innerHTML;
    }else{
      newnote = noteobservationEl.value + '\n' + predefined_observation_descriptionEl.innerHTML;
    }
    noteobservationEl.value = replacenames(newnote, firstname, lastname);
  });
}
if(abortnotechangeEl && noteobservationEl){
  abortnotechangeEl.addEventListener('click', (event) => {
    noteobservationEl.value = abortnotechangeEl.dataset.currentnote;
  });
}
});