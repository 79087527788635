document.addEventListener('DOMContentLoaded', () => {
    const stripeCheckoutEl = document.getElementById("stripe-checkout");
    if (stripeCheckoutEl){
        stripeCheckoutEl.addEventListener('click', (event) => {
            const stripePublishableKey = event.target.dataset.stripepublishablekey;
            const stripeSessionId = event.target.dataset.stripesessionid;
            const stripe = Stripe(stripePublishableKey);

            stripe.redirectToCheckout({
                // Make the id field from the Checkout Session creation API response
                // available to this file, so you can provide it as parameter here
                // instead of the {{CHECKOUT_SESSION_ID}} placeholder.
                sessionId: stripeSessionId
              }).then((result) => {
                // If `redirectToCheckout` fails due to a browser or network
                // error, display the localized error message to your customer
                // using `result.error.message`.
                if (result.error) {
                    alert(result.error.message);
                } else {
                    // TODO : pass information to server
                }
              });
            
          });
    }
});