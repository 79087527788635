document.addEventListener('DOMContentLoaded', () => {

    const closeSideNavEl = document.querySelector(".closebtn");
    if (closeSideNavEl) {
        closeSideNavEl.addEventListener("click", function() {
            const mySideNavEl = document.getElementById("mySidenav");
            if (mySideNavEl) {
                mySideNavEl.style.width = "0";
            }
        });
    }
    const openSideNavEl = document.querySelector(".openbtn");
    if (openSideNavEl) {
        openSideNavEl.addEventListener("click", function() {
            const mySideNavEl = document.getElementById("mySidenav");
            if (mySideNavEl) {
                mySideNavEl.style.width = "150px";
            }
        });
    }

});